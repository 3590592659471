import applicationPaths from "./applicationPaths";
import featuresNames from "./featuresNames";
import Translator from "./Translator";
import "./css/nexusMenuStructure.css";

// menu item is shown when menu variant is provided by the client app
const MENU_VARIANT_SHOW = true; // eslint-disable-line
// menu item is hidden when menu variant is provided by the client app
const MENU_VARIANT_HIDE = false;

export default class NexusMenuStructure {
	constructor(appConfig) {
		this.appConfig = appConfig;
		this.translator = new Translator(appConfig.locale ? appConfig.locale : "da_dk");
		this.defaultMenuVariants = {
			nativeApp: MENU_VARIANT_HIDE
		};
	}

	noPreferenceUrls() {
		return {
			main(filterContext) { return `/noPreferences?context=${filterContext}`; },
			patient(filterContext, patientId) { return `/citizen/${patientId}/noPatientPreferences?context=${filterContext}`; },
			admin(filterContext) { return `/noPreferences?context=${filterContext}`; }
		};
	}

	main() {
		return [
			{
				key: "home",
				title: this.translator.translate("mainMenu_overview"),
				iconCssClass: "nexus-menu-structure-icon-home",
				fillWithFilter: "CROSS_CITIZEN_DASHBOARD",
				filterUrl: "/dashboard/filter/{id}"
			},
			{
				key: "citizens",
				title: this.translator.translate("mainMenu_citizens"),
				iconCssClass: "nexus-menu-structure-icon-citizens",
				fillWithFilter: "CITIZEN_LIST",
				filterUrl: "/citizen/filter/{id}"
			},
			{
				key: "activitylist",
				title: this.translator.translate("mainMenu_activityList"),
				iconCssClass: "nexus-menu-structure-icon-activitylist",
				fillWithFilter: "ACTIVITY_LIST",
				filterUrl: "/activitylist/filter/{id}"
			},
			{
				key: "calendar",
				title: this.translator.translate("mainMenu_calendar"),
				iconCssClass: "nexus-menu-structure-icon-calendar",
				fillWithFilter: "CROSS_CITIZEN_CALENDAR",
				filterUrl: "/calendar/menu/filter/{id}"
			},
			{
				key: "data",
				title: this.translator.translate("mainMenu_data"),
				iconCssClass: "nexus-menu-structure-icon-data",
				fillWithFilter: "CROSS_CITIZEN_DATA",
				filterUrl: "/crosspatientdata/filter/{id}"
			},
			{
				key: "advis",
				title: this.translator.translate("mainMenu_advise"),
				defaultUrl: "/advis/inbox",
				iconCssClass: "nexus-menu-structure-icon-advis",
				submenu: [
					{
						key: "advise_inbox",
						title: this.translator.translate("mainMenu_advise_inbox"),
						url: "/advis/inbox",
						requiresLink: "advisInboxList"
					},
					{
						key: "advise_draft",
						title: this.translator.translate("mainMenu_advise_draft"),
						url: "/advis/draft",
						requiresLink: "advisDraftList"
					},
					{
						key: "advise_outbox",
						title: this.translator.translate("mainMenu_advise_outbox"),
						url: "/advis/outbox",
						requiresLink: "advisOutboxList"
					},
					{
						key: "advise_archive",
						title: this.translator.translate("mainMenu_advise_archive"),
						url: "/advis/archive",
						requiresLink: "advisArchivedList"
					}
				]
			},
			{
				key: "waitinglist",
				title: this.translator.translate("mainMenu_waitingList"),
				iconCssClass: "nexus-menu-structure-icon-waitinglist",
				fillWithFilter: "PHYSICAL_RESOURCE_ASSIGNMENT_LIST",
				filterUrl: "/waitinglist/{id}"
			},
			{
				key: "fordelingslister",
				title: this.translator.translate("mainMenu_distributionLists"),
				iconCssClass: "nexus-menu-structure-icon-fordelingslister",
				fillWithFilter: "PATHWAY_DISTRIBUTION_LIST",
				filterUrl: "/distributionList/filter/{id}"
			},
			{
				key: "finance",
				title: this.translator.translate("mainMenu_finance"),
				iconCssClass: "nexus-menu-structure-icon-finance",
				submenu: [
					{
						key: "finance_billingMunicipalList",
						title: this.translator.translate("mainMenu_finance_billingMunicipalList"),
						url: "/financialTransaction/billingMunicipal/list",
						requiresLink: "financialTransactions"
					},
					{
						key: "finance_billingMunicipalPlanning",
						title: this.translator.translate("mainMenu_finance_billingMunicipalPlanning"),
						url: "/financialTransaction/billingMunicipal/planning",
						requiresLink: "plannedMunicipalBilling"
					},
					{
						key: "finance_billingPatientList",
						title: this.translator.translate("mainMenu_finance_billingPatientList"),
						url: "/financialTransaction/billingPatient/list",
						requiresLink: "financialTransactionPatientGroups"
					},
					{
						key: "finance_billingPatientPlanning",
						title: this.translator.translate("mainMenu_finance_billingPatientPlanning"),
						url: "/financialTransaction/billingPatient/planning",
						requiresLink: "plannedPatientBilling"
					},
					{
						key: "finance_fkInvoiceActiveDocuments",
						title: this.translator.translate("mainMenu_finance_fkInvoiceActiveDocuments"),
						url: "/fk-invoice/documents/active",
						requiresLink: "fkInvoiceActiveDocuments"
					},
					{
						key: "finance_fkInvoiceAllDocuments",
						title: this.translator.translate("mainMenu_finance_fkInvoiceAllDocuments"),
						url: "/fk-invoice/documents/all",
						requiresLink: "fkInvoiceAllDocuments"
					},
					{
						key: "finance_payoutTransactions",
						title: this.translator.translate("mainMenu_finance_payoutTransactions"),
						url: "/finance-payouts/transactions",
						requiresLink: "payoutTransactions"
					},
					{
						key: "finance_citizenCompensationGroups",
						title: this.translator.translate("mainMenu_finance_citizenCompensationGroups"),
						url: "/citizenaccount/citizenCompensationGroups",
						requiresLink: "citizenCompensationGroups"
					},
					{
						key: "finance_exportTransactionGroups",
						title: this.translator.translate("mainMenu_finance_exportTransactionGroups"),
						url: "/financialTransaction/exportTransactionGroups",
						requiresLink: "exportTransactionGroups"
					},
					{
						key: "finance_payoutExportLogs",
						title: this.translator.translate("mainMenu_finance_payoutExportLogs"),
						url: "/finance-payouts/export-logs",
						requiresLink: "payoutExportLogs"
					}
				]
			},
			{
				key: "effectuation",
				title: this.translator.translate("mainMenu_effectuation"),
				iconCssClass: "nexus-menu-structure-icon-effectuation",
				defaultUrl: "/medicine-ui/effectuations/handoutList",
				requiresLink: "internalEffectuations",
				submenu: [
					{
						key: "effectuation_internalEffectuations",
						title: this.translator.translate("mainMenu_effectuation_internalEffectuations"),
						url: "/medicine-ui/effectuations/handoutList",
						requiresLink: "internalEffectuations"
					},
					{
						key: "effectuation_drugInventoryItems",
						title: this.translator.translate("mainMenu_effectuation_drugInventoryItems"),
						url: "/medicine/drugInventory/items",
						requiresLink: "drugInventoryItems"
					},
					{
						key: "effectuation_labelPrint",
						title: this.translator.translate("mainMenu_effectuation_labelPrint"),
						url: "/medicine/effectuations/labels/page",
						requiresLink: "labelPrint"
					}
				]
			},
			{
				key: "hcl_depot",
				title: this.translator.translate("mainMenu_depot"),
				iconCssClass: "nexus-menu-structure-icon-hcl",
				fillWithMultipleFilters: {
					HCL_ORDER: {
						url: "/hcl-logistics/filters/orderfilterconfigurations?filterId={id}"
					},
					HCL_PRODUCT_ITEM: {
						url: "/hcl-logistics/filters/productitemfilterconfigurations?filterId={id}"
					}
				},
				submenu: [
					{
						key: "hcl_depot_repairsView",
						title: this.translator.translate("mainMenu_depot_repairsView"),
						url: "/hcl-logistics/repairs",
						requiresLink: "hclRepairsView"
					}
				]
			}
		];
	}

	patient(patientId) {
		const basePatientUrl = `/citizen/${patientId}`;
		const medicineUiBasePatientUrl = `/medicine-ui/citizen/${patientId}`;
		return [
			{
				key: "patient_overview",
				title: this.translator.translate("patientMenu_overview"),
				fillWithFilter: "CITIZEN_DASHBOARD",
				contextName: "CITIZEN_DASHBOARD",
				defaultUrl: "",
				filterUrl: `${basePatientUrl}/dashboard/filters/{id}`
			},
			{
				key: "patient_citizenPathway",
				title: this.translator.translate("patientMenu_citizenPathway"),
				fillWithFilter: "CITIZEN_PATHWAY",
				contextName: "CITIZEN_PATHWAY",
				defaultUrl: "",
				filterUrl: `${basePatientUrl}/pathway/filters/{id}`
			},
			{
				key: "patient_calendar",
				title: this.translator.translate("patientMenu_calendar"),
				fillWithFilter: "CITIZEN_CALENDAR",
				contextName: "CITIZEN_CALENDAR",
				defaultUrl: "",
				filterUrl: `${basePatientUrl}/calendar/menu/filter/{id}`,
				urlWildcard: `${basePatientUrl}/calendar/filter/{id}/**`
			},
			{
				key: "patient_correspondence",
				title: this.translator.translate("patientMenu_correspondence"),
				defaultUrl: `${basePatientUrl}/correspondence/inbox`,
				contextName: "CORRESPONDENCE",
				submenu: [
					{
						key: "patient_correspondence_inbox",
						title: this.translator.translate("patientMenu_correspondence_inbox"),
						pageId: "inbox",
						url: `${basePatientUrl}/correspondence/inbox`,
						requiresLink: "inboxMessages"
					},
					{
						key: "patient_correspondence_draft",
						title: this.translator.translate("patientMenu_correspondence_draft"),
						pageId: "draft",
						url: `${basePatientUrl}/correspondence/draft`,
						requiresLink: "draftMessages"
					},
					{
						key: "patient_correspondence_outbox",
						title: this.translator.translate("patientMenu_correspondence_outbox"),
						pageId: "outbox",
						url: `${basePatientUrl}/correspondence/outbox`,
						requiresLink: "outboxMessages"
					},
					{
						key: "patient_correspondence_deleted",
						title: this.translator.translate("patientMenu_correspondence_deleted"),
						url: `${basePatientUrl}/correspondence/deleted`,
						requiresLink: "deletedMessages"
					}
				]
			},

			{
				key: "patient_citizenData",
				title: this.translator.translate("patientMenu_citizenData"),
				defaultUrl: "",
				fillWithFilter: "CITIZEN_DATA",
				contextName: "CITIZEN_DATA",
				filterUrl: `${basePatientUrl}/data/filters/{id}`
			},
			{
				key: "patient_medicine",
				title: this.translator.translate("patientMenu_medicine"),
				defaultUrl: `${medicineUiBasePatientUrl}/medicine/medicationCard/current`,
				requiresLink: "medicationCard",
				contextName: "MEDICINE",
				submenu: [
					{
						key: "patient_medicine_current",
						title: this.translator.translate("patientMenu_medicine_current"),
						pageId: "current",
						url: `${medicineUiBasePatientUrl}/medicine/medicationCard/current`,
						requiresLink: "medicationCard"
					},
					{
						key: "patient_medicine_dispensing",
						title: this.translator.translate("patientMenu_medicine_dispensing"),
						url: `${medicineUiBasePatientUrl}/medicine/dispensing`,
						requiresLink: "medicineDispensing"
					},
					{
						key: "patient_medicine_handout",
						title: this.translator.translate("patientMenu_medicine_handout"),
						pageId: "handout",
						url: `${medicineUiBasePatientUrl}/medicine/medicationCard/handout`,
						requiresLink: "medicationCardInViewModeHandout"
					},
					{
						key: "patient_medicine_nursing",
						title: this.translator.translate("patientMenu_medicine_nursing"),
						pageId: "nursing",
						url: `${medicineUiBasePatientUrl}/medicine/medicationCard/nursing`,
						requiresLink: "medicationCardInViewModeNursing"
					},
					{
						key: "patient_medicine_dispensation",
						title: this.translator.translate("patientMenu_medicine_dispensation"),
						url: `${basePatientUrl}/medicine/medicationCard/dispensation`,
						requiresLink: "pnMedications"
					},
					{
						key: "patient_medicine_history",
						title: this.translator.translate("patientMenu_medicine_history"),
						pageId: "historical",
						url: `${basePatientUrl}/medicine/medicationCard/history`,
						requiresLink: "medicationCard"
					}
				]
			},
			{
				key: "patient_finance",
				title: this.translator.translate("patientMenu_finance"),
				defaultUrl: "",
				requiresLink: "financeInsight",
				submenu: [
					{
						requiresLink: "financeInsight",
						title: "patientMenu_finance_overview",
						url: `${basePatientUrl}/financeInsight/overview`
					}
				]
			},
			{
				key: "patient_finance",
				title: this.translator.translate("patientMenu_finance"),
				defaultUrl: "",
				fillWithFilter: "CITIZEN_FINANCE_INSIGHT",
				contextName: "CITIZEN_FINANCE_INSIGHT",
				filterUrl: `${basePatientUrl}/financeInsight/filter/{id}`,
				requiresLink: "financeInsightFilters"
			},
			{
				key: "patient_hclMatching",
				title: this.translator.translate("patientMenu_hclMatching"),
				contextName: "HCL",
				defaultUrl: `/hcl-matching${basePatientUrl}/overview/current`,
				urlWildcard: `/hcl-matching${basePatientUrl}/**`,
				requiresLink: "lendings",
				submenu: [
					{
						key: "patient_hclMatching_current",
						title: this.translator.translate("patientMenu_hclMatching_current"),
						pageId: "hcl_current",
						url: `/hcl-matching${basePatientUrl}/overview/current`,
						requiresLink: "lendings"
					},
					{
						key: "patient_hclMatching_history",
						title: this.translator.translate("patientMenu_hclMatching_history"),
						pageId: "hcl_history",
						url: `/hcl-matching${basePatientUrl}/overview/history`,
						requiresLink: "lendings"
					}
				]
			}
		];
	}

	admin() {
		return [
			{
				key: "admin_general",
				title: this.translator.translate("adminMenu_general"),
				defaultUrl: "",
				submenu: [
					{
						key: "admin_professionalAutosignatures",
						title: this.translator.translate("adminMenu_professionalAutosignatures"),
						url: "/professionalAutosignature/",
						requiresLink: "adminProfessionalAutosignatures"
					},
					{
						key: "admin_homeAdjustment",
						title: this.translator.translate("adminMenu_homeAdjustment"),
						url: "/homeAdjustment/",
						requiresLink: "adminHomeAdjustment"
					},
					{
						key: "admin_patientCategory",
						title: this.translator.translate("adminMenu_patientCategory"),
						url: "/citizen/administration/categories",
						requiresLink: "adminPatientCategory"
					},
					{
						key: "admin_patientStates",
						title: this.translator.translate("adminMenu_patientStates"),
						url: "/patientState/",
						requiresLink: "adminPatientStates"
					},
					{
						key: "admin_unprocessedPatients",
						title: this.translator.translate("adminMenu_unprocessedPatients"),
						url: "/unprocessedPatients/",
						requiresLink: "unprocessedPatients"
					},
					{
						key: "admin_letterTemplates",
						title: this.translator.translate("adminMenu_letterTemplates"),
						url: "/letterTemplate/",
						requiresLink: "adminLetterTemplates"
					},
					{
						key: "admin_pathwayTypes",
						title: this.translator.translate("adminMenu_pathwayTypes"),
						url: "/pathwayType/",
						requiresLink: "adminPathwayTypes"
					},
					{
						key: "admin_kleNumbers",
						title: this.translator.translate("adminMenu_kleNumbers"),
						url: "/sts-classifications/kleNumbers",
						requiresLink: "adminKleNumbers"
					},
					{
						key: "admin_subjectTemplates",
						title: this.translator.translate("adminMenu_subjectTemplates"),
						url: "/subjectTemplate/",
						requiresLink: "adminSubjectTemplates"
					},
					{
						key: "admin_grant_offer_portal_suppliers",
						title: this.translator.translate("adminMenu_grant_offer_portal_suppliers"),
						url: "/grantOfferPortalSupplier/",
						requiresLink: "grantOfferPortalSuppliers"
					},
					{
						key: "admin_suppliers",
						title: this.translator.translate("adminMenu_suppliers"),
						url: "/supplier/",
						requiresLink: "adminSuppliers"
					},
					{
						key: "admin_logViewer",
						title: this.translator.translate("adminMenu_logViewer"),
						url: this.appConfig.getLogViewerOpenSearchUrl ? this.appConfig.getLogViewerOpenSearchUrl() : "#",
						target: "_blank",
						requiresLink: "adminLogViewer",
						renderCondition: () => !!this.appConfig.getLogViewerOpenSearchUrl
					},
					{
						key: "admin_locations",
						title: this.translator.translate("adminMenu_locations"),
						url: "/location/",
						requiresLink: "adminLocations"
					},
					{
						key: "admin_mobileProfile",
						title: this.translator.translate("adminMenu_mobileProfile"),
						url: "/mobileProfile/",
						requiresLink: "adminPreferences"
					},
					{
						key: "admin_mobileProfile",
						title: this.translator.translate("adminMenu_mobileProfile"),
						url: "/mobile-profile-ui/profiles",
						requiresLink: "adminPreferences",
						renderCondition: featureFlags => featureFlags.isEnabled(featuresNames.NEW_MOBILE_PROFILE)
					},
					{
						key: "admin_measurements",
						title: this.translator.translate("adminMenu_measurements"),
						url: "/measurements/",
						requiresLink: "adminMeasurementDefinitions"
					},
					{
						key: "admin_assignments",
						title: this.translator.translate("adminMenu_assignments"),
						url: "/assignmentTypes/",
						requiresLink: "adminAssignmentTypes"
					},
					{
						key: "admin_assignment_pause_reasons",
						title: this.translator.translate("adminMenu_assignment_pause_reasons"),
						url: "/assignmentPauseReasons/",
						requiresLink: "adminAssignmentPauseReasons"
					},
					{
						key: "admin_organizations",
						title: this.translator.translate("adminMenu_organizations"),
						url: "/rbac/organizations",
						requiresLink: "adminOrganizations"
					},
					{
						key: "admin_paragraphs",
						title: this.translator.translate("adminMenu_paragraphs"),
						url: "/paragraph/",
						requiresLink: "adminParagraphs"
					},
					{
						key: "admin_reminders",
						title: this.translator.translate("adminMenu_reminders"),
						url: "/reminders/configurations",
						requiresLink: "adminReminders"
					},
					{
						key: "admin_reports",
						title: this.translator.translate("adminMenu_reports"),
						url: "/report/",
						requiresLink: "adminReports"
					},
					{
						key: "admin_physicalResources",
						title: this.translator.translate("adminMenu_physicalResources"),
						url: "/physicalResource/list",
						requiresLink: "adminPhysicalResources"
					},
					{
						key: "admin_physicalResourceTypes",
						title: this.translator.translate("adminMenu_physicalResourceTypes"),
						url: "/physicalResourceType/",
						requiresLink: "adminPhysicalResourceTypes"
					},
					{
						key: "admin_professionalLocks",
						title: this.translator.translate("adminMenu_professionalLocks"),
						url: "/lockedProfessional/",
						requiresLink: "adminProfessionalLocks"
					},
					{
						key: "admin_formDefinitions",
						title: this.translator.translate("adminMenu_formDefinitions"),
						url: "/formDefinitions/",
						requiresLink: "adminFormDefinitions",
						renderCondition: featureFlags => !featureFlags.isEnabled(featuresNames.NEW_FORM_DEFINITION_LIST_URL)
					},
					{
						key: "admin_formDefinitions",
						title: this.translator.translate("adminMenu_formDefinitions"),
						url: "/schema-designer/formDefinitions/",
						requiresLink: "schemaDesignerFormDefinitions",
						renderCondition: featureFlags => featureFlags.isEnabled(featuresNames.NEW_FORM_DEFINITION_LIST_URL)
					},
					{
						key: "admin_formDefinitions_new",
						title: this.translator.translate("adminMenu_formDefinitions_new"),
						url: "/schema-designer/formDefinitions/",
						requiresLink: "schemaDesignerFormDefinitions",
						renderCondition: featureFlags => featureFlags.isEnabled(featuresNames.NEW_FORM_DEFINITION_LIST_MENU_ITEM)
					},
					{
						key: "admin_professionalJobs",
						title: this.translator.translate("adminMenu_professionalJobs"),
						url: "/professionalJob/",
						requiresLink: "adminProfessionalJobs"
					},
					{
						key: "admin_sites",
						title: this.translator.translate("adminMenu_sites"),
						url: "/site/",
						requiresLink: "adminSites"
					},
					{
						key: "admin_hospitalStay",
						title: this.translator.translate("adminMenu_hospitalStay"),
						url: "/hospitalStay/",
						requiresLink: "adminHospitalStayStates"
					},
					{
						key: "admin_tabletAppConfiguration",
						title: this.translator.translate("adminMenu_tabletAppConfiguration"),
						url: "/tabletAppConfiguration/configuration/",
						requiresLink: "adminTabletAppConfigurationList"
					},
					{
						key: "admin_tags",
						title: this.translator.translate("adminMenu_tags"),
						url: "/tag/",
						requiresLink: "adminTags"
					},
					{
						key: "admin_shifts",
						title: this.translator.translate("adminMenu_shifts"),
						url: "/shift/",
						requiresLink: "adminShifts"
					}
				]
			},
			{
				key: "admin_programPathways",
				title: this.translator.translate("adminMenu_programPathways"),
				defaultUrl: "",
				fillWithFilter: "PROGRAM_PATHWAY",
				filterUrl: "/pathway/program/{id}"
			},
			{
				key: "admin_catalogGrants",
				title: this.translator.translate("adminMenu_catalogGrants"),
				defaultUrl: "",
				fillWithFilter: "CATALOG_GRANT",
				filterUrl: "/catalogGrant/{id}"
			},
			{
				key: "admin_calendar",
				title: this.translator.translate("adminMenu_calendar"),
				defaultUrl: "/plannedGrantEventType/",
				submenu: [
					{
						key: "admin_eventTypes",
						title: this.translator.translate("adminMenu_eventTypes"),
						url: "/eventType/",
						requiresLink: "adminEventTypes"
					},
					{
						key: "admin_plannedGrantEventTypes",
						title: this.translator.translate("adminMenu_plannedGrantEventTypes"),
						url: "/plannedGrantEventType/",
						requiresLink: "adminPlannedGrantEventTypes"
					},
					{
						key: "admin_groupEventSubTypes",
						title: this.translator.translate("adminMenu_groupEventSubTypes"),
						url: "/groupEventSubType/",
						requiresLink: "adminGroupEventSubTypes"
					},
					{
						key: "admin_groupEventRegistrationStatus",
						title: this.translator.translate("adminMenu_groupEventRegistrationStatus"),
						url: "/groupEventRegistrationStatus/",
						requiresLink: "adminGroupEventRegistrationStatuses"
					},
					{
						key: "admin_groupRegisteredGrantStatuses",
						title: this.translator.translate("adminMenu_groupRegisteredGrantStatuses"),
						url: "/groupRegisteredGrantStatus/",
						requiresLink: "adminGroupRegisteredGrantStatuses"
					},
					{
						key: "admin_registeredGrantStatuses",
						title: this.translator.translate("adminMenu_registeredGrantStatuses"),
						url: "/registeredGrantStatus/",
						requiresLink: "adminRegisteredGrantStatuses"
					},
					{
						key: "admin_resourceEventTypes",
						title: this.translator.translate("adminMenu_resourceEventTypes"),
						url: "/resourceEventType/",
						requiresLink: "adminResourceEventTypes"
					},
					{
						key: "admin_resourceEventStatuses",
						title: this.translator.translate("adminMenu_resourceEventStatuses"),
						url: "/resourceEventStatus/",
						requiresLink: "adminResourceEventStatuses"
					},
					{
						key: "admin_groupPlannedGrantStatuses",
						title: this.translator.translate("adminMenu_groupPlannedGrantStatuses"),
						url: "/groupPlannedGrantStatus/",
						requiresLink: "adminGroupPlannedGrantStatuses"
					},
					{
						key: "admin_plannedGrantStatuses",
						title: this.translator.translate("adminMenu_plannedGrantStatuses"),
						url: "/plannedGrantStatus/",
						requiresLink: "adminPlannedGrantStatuses"
					},
					{
						key: "admin_resourcePlannedGrantStatuses",
						title: this.translator.translate("adminMenu_resourcePlannedGrantStatuses"),
						url: "/resourcePlannedGrantStatus/",
						requiresLink: "adminResourcePlannedGrantStatuses"
					},
					{
						key: "admin_registrationStatus",
						title: this.translator.translate("adminMenu_registrationStatus"),
						url: "/registrationStatus/",
						requiresLink: "registrationStatus"
					},
					{
						key: "admin_calendarRoutes",
						title: this.translator.translate("adminMenu_calendarRoutes"),
						url: "/calendarRoute/",
						requiresLink: "adminCalendarRoutes"
					}
				]
			},
			{
				key: "admin_contacts",
				title: this.translator.translate("adminMenu_contacts"),
				defaultUrl: "/contact/professional/",
				submenu: [
					{
						key: "admin_organizationContacts",
						title: this.translator.translate("adminMenu_organizationContacts"),
						url: "/contact/organisation/",
						requiresLink: "adminOrganisationContacts"
					},
					{
						key: "admin_organizationContactTypes",
						title: this.translator.translate("adminMenu_organizationContactTypes"),
						url: "/contactType/organization/",
						requiresLink: "adminOrganizationContactTypes"
					},
					{
						key: "admin_professionalContacts",
						title: this.translator.translate("adminMenu_professionalContacts"),
						url: "/contact/professional/",
						requiresLink: "adminProfessionalContacts"
					},
					{
						key: "admin_professionalContactTypes",
						title: this.translator.translate("adminMenu_professionalContactTypes"),
						url: "/contactType/professional/",
						requiresLink: "adminProfessionalContactTypes"
					},
					{
						key: "admin_relativeContactTypes",
						title: this.translator.translate("adminMenu_relativeContactTypes"),
						url: "/contactType/relative/",
						requiresLink: "adminRelativeContactTypes"
					}
				]
			},
			{
				key: "admin_hclProducts",
				title: this.translator.translate("adminMenu_hclProducts"),
				defaultUrl: "/hcl-logistics/administration/products",
				submenu: [
					{
						key: "admin_hclAutomation",
						title: this.translator.translate("adminMenu_hclAutomation"),
						url: "/hcl-administration/automation",
						requiresLink: "adminHclAutomation"
					},
					{
						key: "admin_hclCars",
						title: this.translator.translate("adminMenu_hclCars"),
						url: "/hcl-administration/cars",
						requiresLink: "adminHclCars"
					},
					{
						key: "admin_hclDepots",
						title: this.translator.translate("adminMenu_hclDepots"),
						url: "/hcl-administration/depots",
						requiresLink: "adminHclDepots"
					},
					{
						key: "admin_hclProductItemsOwners",
						title: this.translator.translate("adminMenu_hclProductItemsOwners"),
						url: "/hcl-administration/productitemsowners",
						requiresLink: "adminHclProductItemsOwners"
					},
					{
						key: "admin_hclSuppliers",
						title: this.translator.translate("adminMenu_hclSuppliers"),
						url: "/hcl-administration/suppliers",
						requiresLink: "adminHclProductSuppliers"
					},
					{
						key: "admin_hclHmiCatalogues",
						title: this.translator.translate("adminMenu_hclHmiCatalogues"),
						url: "/hcl-logistics/administration/products",
						requiresLink: "adminHclHmiNumbers",
						renderCondition: featureFlags => !featureFlags.isEnabled(featuresNames.HCL_ADMINISTRATION_UI_PRODUCTS)
					},
					{
						key: "admin_hclHmiCatalogues",
						title: this.translator.translate("adminMenu_hclHmiCatalogues"),
						url: "/hcl-administration/products",
						requiresLink: "adminHclHmiNumbers",
						renderCondition: featureFlags => featureFlags.isEnabled(featuresNames.HCL_ADMINISTRATION_UI_PRODUCTS)
					},
					{
						key: "admin_hclDeliveryTypes",
						title: this.translator.translate("adminMenu_hclDeliveryTypes"),
						url: "/hcl-administration/deliverytypes",
						requiresLink: "adminHclDeliveryTypes"
					},
					{
						key: "admin_hclDrivingZones",
						title: this.translator.translate("adminMenu_hclDrivingZones"),
						url: "/hcl-administration/drivingzones",
						requiresLink: "adminHclDrivingZones"
					},
					{
						key: "admin_hclStockPlacements",
						title: this.translator.translate("adminMenu_hclStockPlacements"),
						url: "/hcl-administration/stockplacements",
						requiresLink: "adminHclStockPlacements"
					},
					{
						key: "admin_hclLabelDesign",
						title: this.translator.translate("adminMenu_hclLabelDesign"),
						url: "/hcl-administration/labeldesigns",
						requiresLink: "adminHclLabelDesign"
					},
					{
						key: "admin_hclMinimumInventory",
						title: this.translator.translate("adminMenu_hclMinimumInventory"),
						url: "/hcl-administration/minimuminventory",
						requiresLink: "adminHclMinimumInventory"
					},
					{
						key: "admin_hclOpslag",
						title: this.translator.translate("adminMenu_hclOpslag"),
						url: "/hcl-matching/lookup",
						requiresLink: "hclMobileLookUp"
					}
				]
			},
			{
				key: "admin_medicin",
				title: this.translator.translate("adminMenu_medicin"),
				defaultUrl: "/drugs/",
				submenu: [
					{
						key: "admin_internalDrugs",
						title: this.translator.translate("adminMenu_internalDrugs"),
						url: "/drugs/",
						requiresLink: "adminInternalDrugs"
					},
					{
						key: "admin_inventoryManagement",
						title: this.translator.translate("adminMenu_inventoryManagement"),
						url: "/medicine/druginventorymanagement/",
						requiresLink: "adminInventoryManagement"
					}
				]
			},
			{
				key: "admin_finance",
				title: this.translator.translate("adminMenu_finance"),
				defaultUrl: "",
				submenu: [
					{
						key: "admin_financeConfiguration",
						title: this.translator.translate("adminMenu_financeConfiguration"),
						url: "/fk-invoice/configuration",
						requiresLink: "financeConfiguration"
					},
					{
						key: "admin_financialAccounts",
						title: this.translator.translate("adminMenu_financialAccounts"),
						url: "/financialAccount/",
						requiresLink: "adminFinancialAccounts"
					},
					{
						key: "admin_citizenCompensationRate",
						title: this.translator.translate("adminMenu_citizenCompensationRate"),
						url: "/citizenCompensationRate/",
						requiresLink: "adminCitizenCompensationRates"
					},
					{
						key: "admin_payoutRecipients",
						title: this.translator.translate("adminMenu_payoutRecipients"),
						url: "/finance-payouts/recipients/",
						requiresLink: "adminPayoutRecipients"
					},
					{
						key: "admin_payoutFinancialAccounts",
						title: this.translator.translate("adminMenu_payoutFinancialAccounts"),
						url: "/finance-account/account/",
						requiresLink: "adminPayoutAccounts"
					},
					{
						key: "admin_financeContactCreditors",
						title: this.translator.translate("adminMenu_financeContactCreditors"),
						url: "/finance-contacts/creditors",
						requiresLink: "adminCreditors"
					}
				]
			}
		];
	}

	searchUrls() {
		return {
			patient(patientId) {
				return {
					url: `/citizen/${patientId}`,
					params: {}
				};
			},

			patientsList(query) {
				return {
					url: "/citizen/search",
					params: {
						query
					}
				};
			}
		};
	}

	professionalDialogsUrls() {
		return {
			editPreferences(returnUrl) {
				return {
					url: "/dialog/preference$defaultPageSettingsForm",
					params: {
						returnUrl
					}
				};
			},

			updateProfessional(returnUrl) {
				return {
					url: "/dialog/rbac$professionalEdit",
					params: {
						returnUrl
					}
				};
			},

			updateProfessionalSystemSettings(returnUrl) {
				return {
					url: "/dialog/rbac$professionalSystemSettingsEdit",
					params: {
						returnUrl
					}
				};
			},

			editPerson() {
				return {
					url: "/rbac-ui/person"
				};
			},

			editProfile() {
				return {
					url: "/rbac-ui/profile"
				};
			}
		};
	}

	patientDialogsUrls() {
		return {
			changeProfessionalContact(patientId, contactId, returnUrl) {
				return {
					url: `/dialog/contact_professional$changeById/patientId/${patientId}/professionalId/${contactId}`,
					params: {
						returnUrl
					}
				};
			},
			changeOrganizationContact(patientId, contactId, returnUrl) {
				return {
					url: `/dialog/contact_organisation$changeById/patientId/${patientId}/organisationId/${contactId}`,
					params: {
						returnUrl
					}
				};
			},
			editRelativeContactDetails(patientId, relativeContactId, returnUrl) {
				return {
					url: `/dialog/contact_relative$editById/patientId/${patientId}/relativeId/${relativeContactId}`,
					params: {
						returnUrl
					}
				};
			},
			editPatientRelativeContactDetails(patientId, relativeContactId, returnUrl) {
				return {
					url: `/dialog/contact_relative$editRelativePatientContactDetailsById/patientId/${patientId}/relativeId/${relativeContactId}`,
					params: {
						returnUrl
					}
				};
			},
			changePatient(patientId, returnUrl) {
				return {
					url: `/dialog/patient$changeById/patientId/${patientId}`,
					params: {
						returnUrl
					}
				};
			},
			showFlowerChart(patientId, returnUrl) {
				return {
					url: `/dialog/flows$conditionsChartByPatientId/patientId/${patientId}`,
					params: {
						returnUrl
					}
				};
			},
			showPhoto(citizenId, returnUrl) {
				return {
					url: `/citizen/${citizenId}/photo`,
					params: {
						returnUrl
					}
				};
			},
			showForm(formId, returnUrl) {
				return {
					url: `/dialog/form$changeById/formId/${formId}`,
					params: {
						returnUrl
					}
				};
			}
		};
	}

	getApplicationNameForPath(path) {
		const application = applicationPaths.find(app => app.pattern.test(path));
		return application ? application.name : undefined;
	}

	getDefaultPatientPage(appName, patientId) {
		const application = applicationPaths.find(app => app.name === appName);
		if (application) {
			return `${application.pathPrefix}/citizen/${patientId}`;
		}
		throw new Error(`Can not find application for appName: ${appName}`);
	}
}
